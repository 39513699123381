import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { css } from 'emotion'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import SEO from '../components/SEO'
import Share from '../components/Share'
import Title from '../components/Title';

const main = css({
  fontSize: '1.2rem',

  h1: {
    marginTop: '1em'
  },

  'img': {
    display: 'block',
    margin: '0 auto',
    maxHeight: '100vh'
  },

  '.author': {
    background: '#e6f7e1',
    border: '2px solid #C7EBBC',
    fontSize: '1.1rem !important',

    h4: {
      textDecoration: 'underline solid #73CE57',
      textDecorationSkipInk: 'none',
      textUnderlinePosition: 'under'
    },

    p: {
      marginLeft: '10px'
    },

    marginTop: '2em',
    padding: '0.5em'
  },

  '.content': {
    paddingBottom: '3.5em !important'
  },

  '@media all and (max-width: 450px)': {
    fontSize: '1.1rem',

    '.author': {
      fontSize: '0.9rem !important'
    }
  },

  '@media all and (min-width: 1300px) and (max-width: 1500px)': {
    fontSize: '1rem'
  }
})

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  slug,
  cover_image,
  author
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className={main}>
      {helmet || ''}
      <div className="content">
        {
          cover_image && <Img fluid={cover_image.childImageSharp.fluid} style={{ height: cover_image.childImageSharp.fluid.presentationHeight }}/>
        }
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Share title={title} slug={slug} />

            <Title title={title} />
            <p style={{ fontWeight: 'bold' }}>{description}</p>
            <hr />
            <PostContent content={content} />
            <hr />
            {
              author && author.name &&
              <div className="message is-primary is-flex">
                <div className="has-text-centered">
                  <p className="title is-size-5">{author.name}</p>
                  <figure className="image is-128x128">
                    <img src={author.logo || 'https://res.cloudinary.com/chicasalfrente/image/upload/v1544307277/logo-default.png'} alt="foto autora" />
                  </figure>
                </div>
                <div className="message-body">
                  <p>{author.bio}</p>
                </div>
              </div>
            }
            <hr />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Etiquetas</h4>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  helmet: PropTypes.object,
  cover_image: PropTypes.object,
  author: PropTypes.object
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <SEO
            isBlogPost={true}
            postData={post}
            postImage={post.fields.cover_image_rel ? post.fields.cover_image_rel.childImageSharp.fluid.src : ''}
          />}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        slug={post.fields.slug}
        cover_image={post.fields.cover_image_rel}
        author={post.frontmatter.author}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        author {
          name
          bio
          photo
        }
      }
      fields {
        slug
        cover_image_rel {
          childImageSharp {
              fluid(maxHeight: 450) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationHeight
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`